import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer footer-black bg-2 bg-overlay-black-90">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="social">
                <ul>
                  <li>
                    <a className="facebook" href="https://www.facebook.com/moemamotors/">
                      facebook <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter"
                      href="https://www.instagram.com/moemamotors/"
                    >
                      Instagram <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a className="pinterest" href="https://www.google.com.br/maps/place/Moema+Motors/@-23.6093526,-46.6716195,17z/data=!4m8!1m2!2m1!1smoema+motors!3m4!1s0x94ce5bab70448383:0x2bddff152ea7c665!8m2!3d-23.6093993!4d-46.6703266">
                      Localização <i className="fa fa-map-marker"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row footer_info">
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="about-content">
                <img
                  className="img-fluid"
                  id="logo-footer"
                  src="images/logo-light.png"
                  alt=""
                />
                <p>
                  Veículos novos e seminovos com procedência e qualidade, nosso
                  objetivo é alcançar a satisfação do cliente com veículos
                  selecionados, bom atendimento e taxas de financiamento
                  competitivas faça um visita a nossa loja e conheça nosso amplo
                  estoque.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="usefull-link">
                <h6 className="text-white">Informações de contato</h6>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-clock-o"></i>
                      Seg - Sex: 9:0 ás 19:00. Sab: 9:00 ás 16:00. Dom: Fechado.
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-map-marker"></i>
                      Av. dos Eucaliptos, 700 - Indianópolis São Paulo-SP
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-phone"></i>
                      (11) 2362 - 9599
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send?phone=5511970814791">
                      <i className="fa fa-whatsapp"></i>
                      (11) 9 7081 - 4791
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-envelope"></i>
                      contato@moemamotors.com.br
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p>
                    ©Copyright 2021 Moema Motors por
                    <a
                      href="http://www.potenzaglobalsolutions.com/"
                      target="_blank"
                    > Intermédio Veículos Marketing Digital</a
                    >
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <ul className="list-inline text-lg-right text-center">
                  <li><a href="contact-02.html">Contato </a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;