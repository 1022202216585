import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-slick";
import { id_firebase } from '../content-site';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button } from '@material-ui/core';

function VeiculosRecentes(data) {
    const veiculos = data.veiculos;
    const listVeiculos = veiculos.map((v) =>
        <div className="item" key={v.id}>
            <div className="car-item text-center">
                <div className="car-image">
                    {
                        v.imagens.length > 0 ?
                            v.imagens[0].fileThumb !== undefined ?
                                <img className="img-fluid" src={v.imagens[0].fileThumb} alt=""></img>
                                :
                                <img className="img-fluid" src={v.imagens[0].fileUrl} alt=""></img>
                            : <img className="img-fluid" src="/img/car_default.png" alt='imagem veículo'></img>
                    }
                    <div className="car-overlay-banner">
                        <ul>
                            <li><Link to={"/veiculo/" + v.id}><i className="fa fa-link"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="car-list">
                    <ul className="list-inline">
                        <li><i className="fa fa-registered"></i>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</li>
                        <li><i className="fa fa-cog"></i>{formatCambio(v.vecambio)}</li>
                        <li><i className="fa fa-dashboard"></i>{v.vequilometragem} KM</li>
                    </ul>
                </div>
                <div className="car-content">
                    <span>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</span>
                    <Link to={"/veiculo/" + v.id}>{v.vefipe_marca} {v.vefipe_name}</Link>
                    <div className="separator"></div>
                    <div className="price">
                        <span className="new-price">{formatMoney(v.vevalor)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    return <OwlCarousel className='owl-theme' autoplay={true} loop={true} items={2} margin={30} nav>{listVeiculos}</OwlCarousel>

}


function ImagensLoja(data) {

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        accessibility: true
    };

    const imagens = data.images;
    const listImagens = imagens.map((key) =>
        <div key={key}><img className="img-responsive center-block" src={key} alt={"Banner " + data.revenda.penome_fantasia} /></div>
    );
    return <Slider {...settings}>{listImagens}</Slider>;

}

class Inicio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
            });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        veiculos: items,
                        veiculosComplete: items,
                        countVeiculos: items.length
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("imagem_destaque").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    items.push(document);
                });
                if (this.mounted) {
                    this.setState({
                        instagramData: items
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("banner_site").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document.imagem_url !== undefined && document.imagem_url.trim().length > 0) {
                        items.push(document.imagem_url);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        bannerData: items
                    });
                }
            });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render() {
        const {
            veiculos,
            instagramData,
            tipoVeiculo,
            bannerData
        } = this.state;

        if (this.state.revenda.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <div
                    id="rev_slider_6_1_wrapper"
                    className="rev_slider_wrapper fullwidthbanner-container rev_slider_6_1_wrapper"
                    data-alias="slider-7"
                >

                    <div
                        id="rev_slider_6_1"
                        className="rev_slider fullwidthabanner"
                        style={{ display: "none" }}
                        data-version="5.2.6"
                    >
                        <ul>
                            <li
                                data-index="rs-11"
                                data-transition="random-static,random,random-premium"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="revolution/assets/slider-8/100x50_mainbg2.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="revolution/assets/slider-8/mainbg2.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption tp-resizeme slide-11-layer-6"
                                    id="slide-11-layer-6"
                                    data-x="['left','left','center','center']"
                                    data-hoffset="['0','15','0','0']"
                                    data-y="['top','top','top','top']"
                                    data-voffset="['110','131','69','79']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_in="x:right;s:820;e:Power2.easeInOut;"
                                    data-transform_out="x:left;s:650;e:Power3.easeIn;"
                                    data-start="850"
                                    data-responsive_offset="on"
                                    data-end="8350"
                                >
                                    <img
                                        src="revolution/assets/slider-8/audi_PNG1764.png"
                                        alt=""
                                        data-ww="['653px','586.873417721519','586px','451px']"
                                        data-hh="['237px','213','213px','164px']"
                                        data-no-retina
                                    />
                                </div>

                                <div
                                    className="tp-caption tp-resizeme slide-11-layer-3"
                                    id="slide-11-layer-3"
                                    data-x="['left','left','center','center']"
                                    data-hoffset="['710','628','0','0']"
                                    data-y="['top','top','top','top']"
                                    data-voffset="['120','144','303','272']"
                                    data-fontsize="['50','40','40','35']"
                                    data-lineheight="['65','45','45','45']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:-50px;opacity:0;s:1120;e:Power2.easeInOut;"
                                    data-transform_out="opacity:0;s:560;"
                                    data-start="1690"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-end="8380"
                                >
                                    <h3 className='tituloBanner titulocolor1'>Sua loja de Veículos<br/><br/>
                                    em Moema.</h3>
                                </div>

                                <div
                                    className="tp-caption rev-btn slide-11-layer-4"
                                    id="slide-11-layer-4"
                                    data-x="['left','left','center','center']"
                                    data-hoffset="['720','636','0','0']"
                                    data-y="['top','top','top','top']"
                                    data-voffset="['289','271','421','389']"
                                    data-fontsize="['17','17','14','13']"
                                    data-lineheight="['17','17','14','13']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:0;e:Linear.easeNone;"
                                    data-style_hover="c:rgba(0,0,0,1);bg:rgba(255,255,255,1);"
                                    data-transform_in="y:50px;opacity:0;s:760;e:Power2.easeInOut;"
                                    data-transform_out="opacity:0;s:540;"
                                    data-start="2340"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-responsive="off"
                                    data-end="8410"
                                ></div>
                            </li>

                            <li
                                data-index="rs-12"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="revolution/assets/slider-8/100x50_mainbg3.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="revolution/assets/slider-8/mainbg3.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption tp-resizeme slide-12-layer-6"
                                    id="slide-12-layer-6"
                                    data-x="['left','left','left','left']"
                                    data-hoffset="['50','50','249','101']"
                                    data-y="['top','middle','middle','middle']"
                                    data-voffset="['20','0','-98','-93']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_in="x:-50px;opacity:0;s:1200;e:Power2.easeInOut;"
                                    data-transform_out="opacity:0;s:800;"
                                    data-start="1330"
                                    data-responsive_offset="on"
                                >
                                    <img
                                        src="revolution/assets/slider-8/left-car.png"
                                        alt=""
                                        data-ww="['223px','197px','141px','141px']"
                                        data-hh="['403px','357px','255px','255px']"
                                        data-no-retina
                                    />
                                </div>

                                <div
                                    className="tp-caption tp-resizeme slide-12-layer-7"
                                    id="slide-12-layer-7"
                                    data-x="['left','left','left','left']"
                                    data-hoffset="['273','247','389','242']"
                                    data-y="['top','middle','middle','middle']"
                                    data-voffset="['21','1','-97','-91']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_in="x:50px;opacity:0;s:1210;e:Power2.easeInOut;"
                                    data-transform_out="opacity:0;s:800;"
                                    data-start="1310"
                                    data-responsive_offset="on"
                                >
                                    <img
                                        src="revolution/assets/slider-8/right-car.png"
                                        alt=""
                                        data-ww="['250px','224px','157.76425855513307','157.76425855513307']"
                                        data-hh="['401px','360px','253','253']"
                                        data-no-retina
                                    />
                                </div>

                                <div
                                    className="tp-caption tp-resizeme slide-12-layer-3"
                                    id="slide-12-layer-3"
                                    data-x="['left','left','center','center']"
                                    data-hoffset="['709','600','0','0']"
                                    data-y="['top','top','top','top']"
                                    data-voffset="['121','130','297','310']"
                                    data-fontsize="['65','45','45','40']"
                                    data-lineheight="['65','50','50','40']"
                                    data-color="['rgba(255, 255, 255, 1.00)','rgba(255, 255, 255, 1.00)','rgba(10, 10, 10, 1.00)','rgba(10, 10, 10, 1.00)']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:-50px;opacity:0;s:730;e:Power2.easeInOut;"
                                    data-transform_out="opacity:0;s:770;"
                                    data-start="1910"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                >
                                    <h3 className='tituloBanner titulocolor2'>Veículos novos<br/><br/>e seminovos.</h3>
                                </div>

                                <div
                                    className="tp-caption rev-btn slide-12-layer-4"
                                    id="slide-12-layer-4"
                                    data-x="['left','left','center','center']"
                                    data-hoffset="['720','607','0','-1']"
                                    data-y="['top','top','top','top']"
                                    data-voffset="['290','266','420','412']"
                                    data-fontsize="['17','17','14','13']"
                                    data-lineheight="['17','17','14','13']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:0;e:Linear.easeNone;"
                                    data-style_hover="c:rgba(0,0,0,1);bg:rgba(255,255,255,1);"
                                    data-transform_in="y:50px;opacity:0;s:940;e:Power2.easeInOut;"
                                    data-transform_out="opacity:0;s:820;"
                                    data-start="2350"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-responsive="off"
                                ></div>
                            </li>
                        </ul>
                        <div
                            className="tp-bannertimer tp-bottom"
                            style={{ visibility: "hidden !important" }}
                        ></div>
                    </div>
                </div>

                <section className="feature-car white-bg page-section-ptb">
                    <div className="col-md-12">
                        <div className="section-title">
                            <span>Confira nosso estoque</span>
                            <h2>Veículos recentes</h2>
                            <div className="separator"></div>
                        </div>
                    </div>
                </section>

                <section className="product-listing page-section-ptb" style={{ paddingTop: "10px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="col-lg-6">
                                                <TextValidator placeholder="Ex.: Fiat Palio"
                                                    label="Encontre seu veículo"
                                                    onChange={this.handleChange}
                                                    name="buscaVeiculo"
                                                    value={this.state.buscaVeiculo}
                                                    className="form-ui-bootstrap" />

                                            </div>

                                            <div className="col-lg-3" style={{ paddingTop: "27px" }}>
                                                <SelectValidator
                                                    value={tipoVeiculo}
                                                    name="tipoVeiculo"
                                                    className="form-ui-bootstrap select-busca-veiculos"
                                                    onChange={this.handleChange}>
                                                    <MenuItem value="todos">Todos</MenuItem>
                                                    <MenuItem value="novos">0 Km</MenuItem>
                                                    <MenuItem value="seminovos">Seminovos</MenuItem>
                                                </SelectValidator>
                                            </div>

                                            <div className="col-lg-3 text-center">
                                                <button type="submit" className="btn m-btn">BUSCAR VEÍCULOS<span className="fa fa-angle-right"></span></button><br />
                                                <p className="linkLimparBusca text-center" onClick={this.handleClick}>LIMPAR BUSCA</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="row sorting-options">
                                        <div className="col-lg-5">
                                            <div className="change-view-button">
                                                <a className="active" href="#"> <i className="fa fa-th"></i> </a>
                                                <a href="listing-02.html"> <i className="fa fa-list-ul"></i> </a>
                                            </div>
                                        </div> 
                                        
                                    </div>
*/}
                                </ValidatorForm>

                                <div className="row">
                                    {Object.keys(veiculos)
                                        .map(key => {
                                            return (
                                                <div className="col-lg-4" data-wow-delay="0.5s" key={veiculos[key].id}>
                                                    <div className="car-item gray-bg text-center">
                                                        <div className="car-image">
                                                            {
                                                                veiculos[key].imagens.length > 0 ?
                                                                    veiculos[key].imagens[0].fileThumb !== undefined ?
                                                                        <img className="img-fluid" src={veiculos[key].imagens[0].fileThumb} alt=""></img>
                                                                        :
                                                                        <img className="img-fluid" src={veiculos[key].imagens[0].fileUrl} alt=""></img>
                                                                    : <img className="img-fluid" src="/images/car_default.png" alt='imagem veículo'></img>
                                                            }

                                                            <div className="car-overlay-banner">
                                                                <ul>
                                                                    <li>
                                                                        <Button className='botaoShare' style={{fontSize:"20px", color:"#FFF"}} onClick={()=>{
                                                                                navigator.share({
                                                                                    title: document.title,
                                                                                    text: "Veículo",
                                                                                    url: "#/veiculo/" + veiculos[key].id
                                                                                })
                                                                            }}>
                                                                            <i className="fa fa-share-alt"> </i>
                                                                        </Button>
                                                                    </li>
                                                                    <li><Link to={"/veiculo/" + veiculos[key].id}><i className="fa fa-dashboard"></i></Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="car-list">
                                                            <ul className="list-inline">
                                                                <li><i className="fa fa-registered"></i>{this.state.veiculos[key].veano_fabricacao}/{this.state.veiculos[key].vefipe_ano_modelo}</li>
                                                                <li><i className="fa fa-cog"></i>{formatCambio(veiculos[key].vecambio)}</li>
                                                                <li><i className="fa fa-dashboard"></i>{veiculos[key].vequilometragem} KM</li>
                                                            </ul>
                                                        </div>
                                                        <div className="car-content">
                                                            <div className="star">
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                            </div>
                                                            <span>{veiculos[key].veano_fabricacao}/{veiculos[key].vefipe_ano_modelo}</span>
                                                            <Link to={"/veiculo/" + veiculos[key].id}>{veiculos[key].vefipe_marca} {veiculos[key].vefipe_name}</Link>
                                                            <div className="separator"></div>
                                                            <div className="price">
                                                                <span className="new-price">{formatMoney(veiculos[key].vevalor)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="why-choose-us bg-2 bg-overlay-black-70 page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span className="text-white">Algumas informações</span>
                                    <h2 className="text-white">Estrutura da loja</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-beetle"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Todas as marcas</h5>
                                        <p>
                                            Encontre o carro que você procura com facilidade, aqui na
                                            Moema Motors você pode encontrar varias opções de veículos
                                            novos e seminovos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-interface-1"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Atendimento</h5>
                                        <p>Confira nossas redes sociais e nosso atendimento online!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-key"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Venda seu veículo</h5>
                                        <p>
                                            Com a avaliação justa no seu veículo e pagamento a vista,
                                            consulte um de nossos vendedores.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-wallet"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Financiamento</h5>
                                        <p>
                                            Em parceria com os principais bancos e financeiras, buscamos
                                            sempre as menores taxas de juros para nosso clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-gearstick"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Blindados</h5>
                                        <p>
                                            Para você que procura maior segurança fornecemos veículos
                                            novos, usados e blindados.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-gas-station"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Garantia</h5>
                                        <p>
                                            Veículos com garantia e um rigoroso processo seletivo antes de
                                            ser anunciado.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="testimonial-1 white-bg page page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span>Entregando sonhos</span>
                                    <h2>Entregas recentes</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="owl-carousel owl-theme"
                                    data-nav-dots="true"
                                    data-nav-arrow="false "
                                    data-items="3"
                                    data-md-items="3"
                                    data-sm-items="2"
                                    data-xs-items="1"
                                    data-space="20"
                                >
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/01.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Alessandro, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/02.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Renato, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/03.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nossos clientes e amigos Edmilson e Isis, pela preferência e confiança em nosso trabalho e parabenizá-los pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/04.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nossos clientes e amigos Simone, Luciano e Gabriela pela preferência e confiança em nosso trabalho e parabenizá-los pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/05.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Priscila, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/06.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Sidvan, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/07.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Pereira, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/08.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nossos clientes e amigos Weverson e amigos, pela preferência e confiança em nosso trabalho e parabenizá-los pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/09.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nossos clientes e amigos Roberto e Alaíde, pela preferência e confiança em nosso trabalho e parabenizá-los pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/10.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Paulo, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="quick-links white-bg">
                    <div className="container">
                        <div className="tuor_virtual">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <span>Conheça nossa loja</span>
                                        <h2>Tour virtual</h2>
                                        <div className="separator"></div>
                                    </div>
                                </div>
                            </div>
                            <iframe
                                id="rev_iframe"
                                title="Tour Virtual"
                                src="https://www.google.com/maps/embed?pb=!4v1635954941495!6m8!1m7!1sCAoSLEFGMVFpcE9qRDRVczBobWRNZUpwVDYxbTV1cmxueU9DNDJBc3BzeUFtZHdR!2m2!1d-23.60946578!2d-46.67036136!3f358.42219481446307!4f-10.479160151151703!5f0.7820865974627469"
                                loading="lazy"
                            >
                            </iframe>
                        </div>
                    </div>
                </section>

                <Rodape revenda={this.state.revenda} />
            </div >
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

export default Inicio