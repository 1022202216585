import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Cabecalho extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        /*   console.log(this.props.revenda); */

        return (

            <header id="header" className="topbar-dark logo-center">
                <div className="topbar">
                    <div className="container">
                        <div className="row menu_info">
                            <div className="col-md-6 menu_info_1">
                                <div className="topbar-left text-md-left text-center">
                                    <ul className="list-inline">
                                        <li>
                                            <i className="fa fa-envelope-o"> </i>contato@moemamotors.com.br
                                        </li>
                                        <li>
                                            <i className="fa fa-clock-o">Seg - Sex 8:30 - 18:00. Sab 9:00 - 12:00. Dom - Fechado.</i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 menu_info_2">
                                <div className="topbar-right text-md-right text-center">
                                    <ul className="list-inline">
                                        <li><i className="fa fa-phone"></i> (11) 2362-9599</li>
                                        <li>
                                            <a href="https://www.facebook.com/moemamotors/"
                                            ><i className="fa fa-facebook"></i
                                            ></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/moemamotors/"
                                            ><i className="fa fa-instagram"></i
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu">
                    <nav id="menu" className="mega-menu">
                        <section className="menu-list-items">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <ul className="menu-logo">
                                            <li>
                                                <a href="index.html"
                                                ><img
                                                        id="logo_dark_img"
                                                        src="images/logo-dark.png"
                                                        alt="logo"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="menu-links">
                                            <li><Link to="/" > INÍCIO</Link></li>
                                            <li><Link to="/estoque" > ESTOQUE</Link></li>
                                            <li><Link to="/vendaseuveiculo" > VENDA SEU VEÍCULO</Link></li>
                                            <li><Link to="/financiamento" > FINANCIAMENTO</Link></li>
                                            <li><Link to="/contato" > CONTATO</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </nav>
                </div>
            </header>


        )
    }

}

export default Cabecalho;